import React from 'react'



const Carrito = () => {
    

    
    return (
        <div>
             
        </div>
    )
}

export default Carrito

