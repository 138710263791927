import React from 'react'
import { Fragment } from 'react/cjs/react.production.min'
import Header from '../header'

const UsuarioRegistrado = () => {
    return (
        <Fragment>
           <Header name="kevin"/>    
        </Fragment>
    )
}

export default UsuarioRegistrado
